import { Image } from "antd";
import { Header } from "antd/lib/layout/layout";
import FASTBANK from "../images/FASTBANK.png";
export const HeaderPage = (props) => {
  return (
    <Header>
      <nav className="header">
        <Image width={320} height={32} src={FASTBANK} preview={false} />
      </nav>
    </Header>
  );
};
