import { API_KEY, API_URL } from "../keys";
const fetchData = async (url:string, option: any) => {
  return new Promise((resolve, reject) => {
    fetch(url, option)
      .then((response) => response.json())
      .then((result) => resolve(result))
      .catch((error) => reject(error));
  });
};

export default class PaymentService {
  static async getPaymentList() {
    var myHeaders = new Headers();
    myHeaders.append("apiKey", API_KEY);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    return fetchData(API_URL, requestOptions);
  }
  static async genQrCode(amount: number, ref: string) {
    var myHeaders = new Headers();
    myHeaders.append("apiKey", API_KEY);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      amount: amount,
      ref: ref,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    return fetchData(
      `${API_URL}genQrCode?accountNo=4241000320`,
      requestOptions
    );
  }
}
