import { Footer } from "antd/lib/layout/layout";

export const FooterPage = (props) => {
  return (
    <Footer>
      <div className="footer-line-top">SECURE WEBSITE GUARANTEE 100%</div>
      <div className="footer-line-bottom">
        © 2022 COPYRIGHT -
        <span className="footer-line-bottom-yellow">FASTBANK</span> ALL RIGHTS
        RESERVED.
      </div>
    </Footer>
  );
};
