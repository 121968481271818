import {
  Button,
  Col,
  Empty,
  Form,
  Image,
  Layout,
  message,
  Modal,
  notification,
  Result,
  Row,
} from "antd";

import Pusher from "pusher-js";
import { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import "./App.scss";
import { FooterPage } from "./component/footer";
import { HeaderPage } from "./component/header";
import NumericInput from "./component/input-number";
import PhoneInput from "./component/input-phone";
import COPY from "./images/copy.png";
import KBANK from "./images/kbank.png";
import SCB from "./images/scb.png";
import TRUEM from "./images/true-money.png";
import { PUSHER_APP_CUSTER, PUSHER_APP_KEY, PUSHER_CHANEL_NAME } from "./keys";
import PaymentService from "./services/payment-service";
const { Content } = Layout;
interface Deposit {
  id: number;
  title: string;
  createdDate: string;
  bankName: string;
  owerName: string;
  accountNo: number;
  etc: string;
  amount: number;
  isRead: boolean;
  info: string;
  checksum: string;
}
const openNotification = (
  message: string,
  description: any,
  duration: number
) => {
  const args = {
    message: message,
    description: description,
    duration: duration,
  };
  notification.open(args);
};
function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const userData = {
  name: "เพ็ญนภา สิง***",
  id: "424-100032-0",
  ref: "",
  amount: 0,
  disableBtn: false,
};
let log: any = [];
export default function App() {
  // const [bankList, setBankList] = useState([
  //   {
  //     src: SCB,
  //     bankName: "ธนาคารไทยพาณิชย์",
  //     bankID: "892-249483-8",
  //     owerName: "ชนัทชา ส***",
  //   },
  //   {
  //     src: KBANK,
  //     bankName: "ธนาคารกสิกรไทย",
  //     bankID: "0383787505",
  //     owerName: "สุทธชาติ วิชัย",
  //   },
  //   {
  //     src: TRUEM,
  //     bankName: "ทรูมันนี่ วอลเล็ท",
  //     bankID: "081 -234-5678",
  //     owerName: "วิชัย ใจถึง",
  //   },
  // ]);
  const [transaction, setTransaction] = useState([]);
  const [qrcode, setQrCode] = useState("");
  const [visibleModalCreateQR, setVisibleModalCreateQR] = useState(false);
  const [form] = Form.useForm();
  const handleOkModalCreateQR = () => {
    setVisibleModalCreateQR(false);
    // dosomething
  };
  const buildDepositModel = (d: any) => {
    const info = d.info.split(",");
    const mock: Deposit = {
      id: d.id,
      title: "DEPosit - " + d.id,
      createdDate: d.createdDate,
      bankName: d.bankCode.toUpperCase(),
      owerName: info.length === 1 ? info[0] : info[1],
      accountNo: info.length === 1 ? info[0] : info[2],
      info: info,
      etc: d.txnDescription ? d.txnDescription : "-",
      amount: d.amount,
      checksum: d.checksum,
      isRead: d.isRead ? d.isRead : true, ///false,
    };
    return mock;
  };
  useEffect(() => {
    loadTransaction();
    return () => {
      setTransaction([]);
      setUserDetails(userData);
      log = [];
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const loadQrCode = (amount: number, ref: string, callBack: any) => {
    PaymentService.genQrCode(amount, ref)
      .then((res: any) => {
        if (res && res.qrCode) {
          setQrCode(res.qrCode);
          if (callBack) {
            callBack();
          }
        }
      })
      .catch((err) => {
        console.log(err);
        message.error("ระบบไม่สามารถเรียก qrcode ได้");
      });
  };
  const loadTransaction = () => {
    console.log("loadTransaction");
    PaymentService.getPaymentList()
      .then((res: any) => {
        if (res && res.length > 0) {
          const data: Deposit[] = [];
          for (const d of res) {
            const mock: Deposit = buildDepositModel(d);
            if (
              !transaction.some((x: Deposit) => x.checksum === mock.checksum)
            ) {
              log.push(d.checksum);
              data.push(mock);
            }
          }
          setTransaction((prev: Deposit[]): any => {
            return prev ? [...data, ...prev] : [];
          });
          callPusher();
        }
      })
      .catch((e) => {
        message.error("ไม่สามารถโหลดบันทึกได้");
        callPusher();
      });
  };

  const callPusher = () => {
    Pusher.logToConsole = true;
    const pusher = new Pusher(PUSHER_APP_KEY, {
      cluster: PUSHER_APP_CUSTER,
    });
    const channel = pusher.subscribe(PUSHER_CHANEL_NAME);
    channel.bind("statement-event", function (data: any) {
      if (data && data.length > 0) {
        const ref = form.getFieldValue("ref");
        const amount = form.getFieldValue("cash");
        const arrSet = new Set<any>(data);
        const arr = Array.from(arrSet.values());
        for (const d of arr) {
          if (log.indexOf(d.checksum) < 0) {
            log.push(d.checksum);
            let timeDuration = 15;
            if (
              d.info.indexOf(ref) >= 0 &&
              Number(d.amount) === Number(amount)
            ) {
              timeDuration = 0;
              setVisibleModalShowQR(false);
              setVisibleModalConfirmQR(true);
              setUserDetails(userData);
            }
            openNotification(
              d.txnDescription + " " + d.bankCode.toUpperCase(),
              <>{d.info}</>,
              timeDuration
            );
            const mock: Deposit = buildDepositModel(d);
            setTransaction((prev: Deposit[]): any => {
              return [mock, ...prev];
            });
          }
        }
      }
    });
  };

  const handleCancelModalCreateQR = () => {
    setVisibleModalCreateQR(false);
  };
  const [currentBank, setCurrentBank] = useState({ id: "scb", name: "SCB" });
  const [visibleModalShowQR, setVisibleModalShowQR] = useState(false);
  const handleOkModalShowQR = () => {
    setVisibleModalShowQR(false);
    //do something
  };
  const [userDetails, setUserDetails] = useState(userData);
  const handleCancelModalShowQR = () => {
    setVisibleModalShowQR(false);
  };
  const [visibleModalConfirmQR, setVisibleModalConfirmQR] = useState(false);
  const handleOkModalConfirmQR = () => {
    setVisibleModalConfirmQR(false);
  };
  const handleCancelModalConfirmQR = () => {
    setVisibleModalConfirmQR(false);
  };
  const onFinishInputValue = (data: any) => {
    if (data.cash && Number(data.cash) > 0 && data.ref) {
      setUserDetails((prev) => ({
        ...prev,
        ref: data.ref,
        amount: data.cash,
        disableBtn: true,
      }));
      loadQrCode(data.cash, data.ref, () => {
        setVisibleModalCreateQR(false);
        setVisibleModalShowQR(true);
        setUserDetails((prev) => ({ ...prev, disableBtn: false }));
      });
    }
  };
  const onFinishFailedInputValue = () => {
    console.log("onFinishFailedInputValue");
  };
  const setModalCreateQrCode = () => {
    form.resetFields();
    setVisibleModalCreateQR(true);
    setCurrentBank({ id: "scb", name: "SCB" });
  };
  return (
    <Layout className="app">
      <HeaderPage />
      <Content>
        <div className="container">
          <Row>
            <Col flex={24}>
              <div className="title">เติมเครดิต</div>
            </Col>
          </Row>

          <Row className="margrintop20 margrinbottom20">
            <Col span={24}>
              <span className="tag-yellow">ขั้นตอนที่ 1</span>
              <span>&nbsp;&nbsp;เลือกบัญชีธนาคาร</span>
            </Col>
          </Row>
          <Row gutter={[8, 8]} className={"bank-row"}>
            <Col flex={8}>
              <div className="bank-slot">
                <div className="bank-image">
                  <Image width={72} height={72} src={SCB} preview={false} />
                </div>
                <div className="bank-details">
                  <div>ธนาคารไทยพาณิชย์</div>
                  <div>424-100032-0</div>
                  <div>เพ็ญนภา สิง***</div>
                </div>
                <div className="bank-copy">
                  <span>
                    <CopyToClipboard
                      text={"4241000320"}
                      onCopy={() => {
                        message.success("คัดลอกแล้ว");
                      }}
                    >
                      <Image
                        className="copy-element"
                        width={24}
                        height={24}
                        src={COPY}
                        preview={false}
                      />
                    </CopyToClipboard>
                  </span>
                </div>
              </div>
              <div
                className="qrcode-action-scb"
                onClick={() => setModalCreateQrCode()}
              >
                <span>เติมเงิน SCB ด้วย QR-code</span>
              </div>
            </Col>
            <Col flex={8}>
              <div className="bank-slot">
                <div className="bank-image">
                  <Image width={72} height={72} src={KBANK} preview={false} />
                </div>
                <div className="bank-details">
                  <div>ธนาคารกสิกรไทย</div>
                  <div>131-324796-8</div>
                  <div>นฤกรณ์ ค***</div>
                </div>
                <div className="bank-copy">
                  <span>
                    <CopyToClipboard
                      text={"1313247968"}
                      onCopy={() => {
                        message.success("คัดลอกแล้ว");
                      }}
                    >
                      <Image
                        className="copy-element"
                        width={24}
                        height={24}
                        src={COPY}
                        preview={false}
                      />
                    </CopyToClipboard>
                  </span>
                </div>
              </div>
              {/* <div
                className="qrcode-action-kbank"
                onClick={() => {
                  setVisibleModalCreateQR(true);
                  setCurrentBank({ id: "kbank", name: "KBANK" });
                }}
              >
                <span>เติมเงิน KBANK ด้วย QR-code</span>
              </div> */}
            </Col>
            <Col flex={8}>
              <div className="bank-slot">
                <div className="bank-image">
                  <Image width={72} height={72} src={TRUEM} preview={false} />
                </div>
                <div className="bank-details">
                  <div>ทรูมันนี่ วอลเล็ท</div>
                  <div>083-319-4030</div>
                  <div>สุทธ***</div>
                </div>
                <div className="bank-copy">
                  <span>
                    <CopyToClipboard
                      text={"0833194030"}
                      onCopy={() => {
                        message.success("คัดลอกแล้ว");
                      }}
                    >
                      <Image
                        className="copy-element"
                        width={24}
                        height={24}
                        src={COPY}
                        preview={false}
                      />
                    </CopyToClipboard>
                  </span>
                </div>
              </div>
              {/* <div
                className="qrcode-action-truem"
                onClick={() => {
                  setVisibleModalCreateQR(true);
                  setCurrentBank({ id: "truem", name: "ทรูมันนี่ วอลเล็ท" });
                }}
              >
                <span>เติมเงิน ทรูมันนี่ ด้วย QR-code</span>
              </div> */}
            </Col>
          </Row>
          <Row className="margrintop20 margrinbottom20">
            <Col span={24}>
              <span className="tag-yellow">ขั้นตอนที่ 2</span>
              <span>
                &nbsp;&nbsp;โอนเงินเพื่อเติมเครดิต ระบบจะทำการรับยอดอัตโนมัติ
              </span>
            </Col>
          </Row>
          <Row className="margrintop10 margrinbottom10">
            <Col span={24}>
              <div className="alertbox-red">โอนขั้นต่ำ "ครั้งละ 1 บาท"</div>
            </Col>
          </Row>
          <Row className="margrintop10 margrinbottom10">
            <Col span={24}>
              <div className="alertbox-yellow">
                คำเตือน! กรุณาใช้บัญชีที่ท่านผูกกับ NAME BUSINESS
                ในการโอนเงินเท่านั้น
              </div>
            </Col>
          </Row>
          <Row className="margrintop10 margrinbottom10">
            <Col span={24}>
              <div className="alertbox-blue">
                เมื่อท่านทำการโอนเงินไปยังบัญชีข้างต้นเรียบร้อยแล้ว
                (เก็บสลิปการโอนไว้ทุกครั้ง)
                ระบบจะทำการรับยอดการเติมเงินอัตโนมัติ
              </div>
            </Col>
          </Row>
          <Row gutter={[8, 8]} className="margrintop10 margrinbottom10">
            <Col span={24}>
              <div className="history-title">
                <h3>ประวัติการเติมเครดิต</h3>
              </div>
            </Col>
            <Col span={24}>
              {transaction.length === 0 && (
                <div className="history-empty">
                  <Empty description={"ยังไม่มีประวัติการทำรายการเติมเงิน"} />
                </div>
              )}
              {transaction.map((data: Deposit, i: number) => (
                <Row key={"transaction" + i} className="card-transaction">
                  <Col flex={8}>
                    <Row>
                      <Col flex={24} className="title-transaction">
                        <div className="title-text">{data.title}</div>
                        <div className="title-date">{data.createdDate}</div>
                      </Col>
                    </Row>
                    <Row gutter={[8, 8]} className="details-transaction">
                      <Col flex={12} className="details-transaction-left">
                        <div>
                          <label>ธนาคาร :</label>
                          <span>{data.bankName}</span>
                        </div>
                        <div>
                          <label>ชื่อบัญชี :</label>
                          <span>{data.owerName}</span>
                        </div>
                        <div>
                          <label>เลขบัญชี :</label>
                          <span>{data.accountNo}</span>
                        </div>
                        <div>
                          <label>หมายเหตุ :</label>
                          <span> {data.etc} </span>
                        </div>
                      </Col>
                      <Col flex={12} className="details-transaction-right">
                        <div className="noti-success-text">
                          + {numberWithCommas(data.amount)}
                        </div>
                        {data.isRead === true && (
                          <div className="noti-success">สำเร็จ</div>
                        )}
                        {data.isRead === false && (
                          <div className="noti-notsuccess">ไม่สำเร็จ</div>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ))}
            </Col>
          </Row>
        </div>
        <Modal
          className="modalInputQr"
          title={null}
          visible={visibleModalCreateQR}
          onOk={handleOkModalCreateQR}
          onCancel={handleCancelModalCreateQR}
          footer={null}
        >
          <Row className="title">
            <h3>{`เติมเงิน ${currentBank.name} ด้วย QR-code`}</h3>
          </Row>
          <Form
            form={form}
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            onFinish={onFinishInputValue}
            onFinishFailed={onFinishFailedInputValue}
            autoComplete="off"
          >
            <Row>
              <Col flex={24} className="money">
                <Form.Item
                  label={null}
                  name="ref"
                  rules={[
                    { required: true, message: "โปรดระบุหมายเลขโทรศัพท์" },
                  ]}
                >
                  <PhoneInput
                    size="large"
                    minLength={10}
                    placeholder="ระบุหมายเลขโทรศัพท์"
                    className="numberCreateQrCode"
                    maxLength={25}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col flex={24} className="money">
                <Form.Item
                  label={null}
                  name="cash"
                  rules={[{ required: true, message: "โปรดระบุจำนวนเงิน" }]}
                >
                  <NumericInput
                    size="large"
                    maxLength={25}
                    placeholder="ระบุจำนวนเงิน"
                    className="numberCreateQrCode"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col flex={24} className="money-btn">
                <Form.Item>
                  <Button
                    size="large"
                    className={"createQrCodeBtn-" + currentBank.id}
                    htmlType="submit"
                    disabled={userDetails.disableBtn}
                    loading={userDetails.disableBtn}
                  >
                    สร้าง QR code
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
        <Modal
          className="modalShowQr"
          title={null}
          visible={visibleModalShowQR}
          onOk={handleOkModalShowQR}
          onCancel={handleCancelModalShowQR}
          footer={null}
        >
          <Row className="title">
            <Col flex={24}>
              <h3>{`เติมเงิน ${currentBank.name} ด้วย QR-code`}</h3>
            </Col>
          </Row>
          <Row className="qr-details">
            <Col flex={24}>
              <span>
                <Image
                  className="qr-element"
                  width={347}
                  height={347}
                  src={qrcode}
                  preview={false}
                />
              </span>
            </Col>
          </Row>
          <Row className="user-details">
            <Col flex={12}>
              <div className="text-center">{userDetails.name}</div>
              <div className="text-center">{userDetails.id}</div>
            </Col>
            <Col flex={12}>
              <div className="text-center">จำนวนเงิน</div>
              <div className="text-center">{userDetails.amount} บาท</div>
            </Col>
          </Row>
        </Modal>
        <Modal
          className="modalSuccess"
          title={null}
          visible={visibleModalConfirmQR}
          onOk={handleOkModalConfirmQR}
          onCancel={handleCancelModalConfirmQR}
          footer={null}
        >
          <Row className="success-details">
            <Col flex={24}>
              <Result
                status="success"
                title="ระบบรับยอดการเติมเงินของคุณเรียบร้อยแล้ว"
                subTitle={null}
                extra={[]}
              />
            </Col>
          </Row>
        </Modal>
      </Content>
      <FooterPage />
    </Layout>
  );
}
